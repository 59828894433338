import React, { useEffect } from "react";

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams, Link } from "react-router-dom";

// import axios from "axios";
// import React, { useEffect, useState } from "react";

import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
// import './assets/css/plugins/imagelightbox.min.css';
import { Link } from "react-router-dom";


import BannerImage from "./assets/images/back10.png";
import bng from "./assets/images/banner image.jpg";
import bng2 from "./assets/images/back13.png";
import RandA from "./assets/images/1.jpg";
import SandG from "./assets/images/5.jpg";
import CandB from "./assets/images/2.jpg";
import FandB from "./assets/images/3.jpg";
import HandW from "./assets/images/4.jpg";
import EandA from "./assets/images/6.jpg";
import m1 from "./assets/images/management/1.jpg";
import m2 from "./assets/images/management/2.jpg";
import m3 from "./assets/images/management/3.jpg";
import m4 from "./assets/images/management/4.jpg";
import m5 from "./assets/images/management/5.jpg";
import m6 from "./assets/images/management/6.jpg";
import m7 from "./assets/images/management/7.jpg";
import m8 from "./assets/images/management/8.jpg";
import m9 from "./assets/images/management/9.jpg";
import m10 from "./assets/images/management/10.jpg";
import m11 from "./assets/images/management/11.jpg";
import m12 from "./assets/images/management/12.jpg";
import m13 from "./assets/images/management/13.jpg";
import m14 from "./assets/images/management/14.jpg";
import m15 from "./assets/images/management/15.jpg";
import m16 from "./assets/images/management/16.jpg";
import m17 from "./assets/images/management/17.jpg";

import upEvents1 from "./assets/images/upevents1.jpg";
import upEvents2 from "./assets/images/upevents2.jpg";
import upEvents3 from "./assets/images/upevents3.jpg";
import slider1 from "./assets/images/slider1.jpg";
import slider2 from "./assets/images/slider2.jpg";
import slider3 from "./assets/images/slider3.jpg";
import slider4 from "./assets/images/slider4.jpg";
import slider5 from "./assets/images/slider5.jpg";
import slider6 from "./assets/images/slider6.jpg";
import upevents4 from "./assets/images/upevents4.jpg";
import slider8 from "./assets/images/slider8.jpg";

import about0 from "./assets/images/aboutSlider/0.jpg";
import about1 from "./assets/images/aboutSlider/1.jpg";
import about2 from "./assets/images/aboutSlider/2.jpg";
import about3 from "./assets/images/aboutSlider/3.jpg";
import about4 from "./assets/images/aboutSlider/4.jpg";
import about5 from "./assets/images/aboutSlider/5.jpg";
import about6 from "./assets/images/aboutSlider/6.jpg";
import about7 from "./assets/images/aboutSlider/7.jpg";
import about8 from "./assets/images/aboutSlider/8.jpg";


import bannerCol1 from "./assets/images/new/bannerCol/1.jpg";
import bannerCol2 from "./assets/images/new/bannerCol/2.jpg";
import bannerCol3 from "./assets/images/new/bannerCol/3.jpg";
import bannerCol4 from "./assets/images/new/bannerCol/4.jpg";
import bannerCol5 from "./assets/images/new/bannerCol/5.jpg";
import bannerCol6 from "./assets/images/new/bannerCol/6.jpg";


import sample from './assets/images/mclubVideo.mp4';

import historyOld2 from "./assets/images/historyOld2.png";
import historyOld3 from "./assets/images/historyOld3.png";
import historyOld4 from "./assets/images/history4.jpeg";
import event1 from "./assets/images/events1.jpg";
import event2 from "./assets/images/events2.jpg";
import event3 from "./assets/images/events3.jpg";

import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';


import "../../App.css";
// import './assets/css/homeModal.css';

import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

export const Home = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "./assets/js/imagelightbox.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show12, setShow12] = useState(false);
    const [show13, setShow13] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const handleCloseActivities = () => setShow1(false);
    const handleShowActivities = () => setShow1(true);

    const handleCloseActivities2 = () => setShow12(false);
    const handleShowActivities2 = () => setShow12(true);

    const handleCloseActivities3 = () => setShow13(false);
    const handleShowActivities3 = () => setShow13(true);
    // const openNav = () => {
    //     let element = document.getElementById('myNav')
    //     element.style.width = '100%';   
    //     if(document.getElementById("firDiv"))   {
    //         alert("con")
    //     }

    // };


    // const navigate = useNavigate();
    // const [user, setUser] = useState([]);
    // const [newticketCount, setticketCount] = useState();
    // useEffect(() => {
    //   axios
    //     .get("http://localhost:3306/get/" + id)
    //     .then((res) => setUser(res.data.Result[0]))
    //     .catch((err) => console.log(err));
    //   axios
    //     .get("http://localhost:3306/newticketCount")
    //     .then((res) => {
    //       setticketCount(res.data[0].tickets);
    //     })
    //     .catch((err) => console.log(err));
    // });



    // const closeNav = () => {
    //     let element = document.getElementById('myNav')
    //     element.style.width = '0%'

    // };

    const bannerVideo = document.querySelector('.banner-video video');

    function pauseVideo() {
        if (isElementInViewport(bannerVideo)) {
            bannerVideo.play();
        } else {
            bannerVideo.pause();
        }
    }

    function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElemement.clientHeight)
        );
    }

    window.addEventListener('scroll', pauseVideo);

    return (
        <div>

            {/* <div id="myNav" className="overlay">
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
                <div id="firDiv" >
                    <div className="overlay-content" >
                        <h2 style={{ color: "#fff" }}>Events</h2>

                        <MDBCarousel showControls showIndicators touch={false}>
                            <MDBCarouselItem itemId={1}>
                                <img src={slider1} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={2}>
                                <img src={slider2} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={3}>
                                <img src={slider3} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={4}>
                                <img src={slider4} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={5}>
                                <img src={slider5} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={6}>
                                <img src={slider6} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={7}>
                                <img src={slider7} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={8}>
                                <img src={slider8} className='w-25' alt='...' />
                            </MDBCarouselItem>
                        </MDBCarousel>
                    </div>
                </div>
                <div id="secDiv">
                    <div className="overlay-content" id="secDiv">
                        <h2 style={{ color: "#fff" }}>Activities</h2>

                        <MDBCarousel showControls showIndicators touch={false}>
                            <MDBCarouselItem itemId={1}>
                                <img src={event1} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={2}>
                                <img src={event2} className='w-25' alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={3}>
                                <img src={event3} className='w-25' alt='...' />
                            </MDBCarouselItem>
                        </MDBCarousel>
                    </div>
                </div>
            </div> */}

            {/* <div class="image-cover hero_banner hero-inner-2" style="background:#032656;" data-overlay="0">
                <div class="container">
                    <div class="row align-items-center">
                        

                    </div>
                </div>
            </div> */}
            <div className="row bannerTop" >
                <div className="col-lg-12 col-sm-12 alignValue" style={{ alignSelf: "self-end" }}>
                    <div class="banner">
                        <img style={{ width: "100%" }} src={bng2} alt="" />
                        {/* <div class="banner-text">
                            
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div class="tab-content bannerTop">
                        <div id="background">

                        </div>
                        <div style={{paddingLeft: "20px"}}>
                            Hello
                        </div>
                    </div> */}
            {/* <div class="row bannerTop" >
                <div className="col-lg-12 col-sm-12 " >
                    
                    <div class="banner">
                        <div class="banner-video">
                            <video className='videoTag' autoPlay loop muted>
                                <source src={sample} type="video/mp4" />
                            </video>
                        </div>
                        <div class="banner-text">
                            <div className="bannerbutton1">
                                <a href="/membership" style={{  }} data-toggle="modal" data-target="#signup">Membership Options</a>
                            </div>&nbsp;&nbsp;
                            <div className="bannerbutton2">
                                <a href={"https://admin.malayaleeclub.org/login.php"} style={{ color: "#000" }} data-toggle="modal" data-target="#signup">Members Login</a>
                            </div>
                        </div>
                    </div>

                    


                </div>
            </div> */}
            <div className="row align-items bannerRow" >
                {/* <div className="col-lg-6 col-sm-12 alignValue" style={{  alignSelf: "self-end" }}>

                    <img  style={{ width: "100%", position: "relative", zIndex: "2" }} src={BannerImage} alt="" />

                </div>
                <div className="col-lg-6 " style={{ alignSelf: "end" }}>

                    <div className="row">
                        <div className="col-md-4">
                        <a href="/rooms"><img className="itemm-22" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol1} alt="" /></a>
                        </div>
                        <div className="col-md-4">
                        <a href="/conference"><img className="itemm-23" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol2} alt="" /></a>
                        </div>
                        <div className="col-md-4">
                        <a href="/food"> <img className="itemm-24" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol3} alt="" /></a>
                        </div>
                        <div className="col-md-4">
                        <a href="/health"> <img className="itemm-25" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol4} alt="" /></a>
                        </div>
                        <div className="col-md-4">
                        <a href="/sports"> <img className="itemm-26" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol5} alt="" /></a>
                        </div>
                        <div className="col-md-4">
                        <a href="/events"> <img className="itemm-21" style={{ width: "100%", marginTop:"1rem", borderRadius:"10px" }} src={bannerCol6} alt="" /></a>

                        </div>

                    </div>
                </div> */}

                {/* <div className="col-lg-5 " style={{ alignSelf: "end", paddingLeft: "0px" }}>
                    <div className="gallery-wrap heightTarget2 marginPlace eventsClick" id="slide"  >
                        <a href="/rooms" className="itemmBannerSlide itemm-21"></a>
                        <a href="/conference" className="itemmBannerSlide itemm-22"></a>
                        <a href="/food" className="itemmBannerSlide itemm-23"></a>
                        <a href="/health" className="itemmBannerSlide itemm-24"></a>
                        <a href="/sports" className="itemmBannerSlide itemm-25"></a>
                        <a href="/events" className="itemmBannerSlide itemm-26"></a>
                        <a href="/rooms" className="itemmBannerSlide itemm-27"></a>
                        <a href="/conference" className="itemmBannerSlide itemm-28"></a>
                        <a href="/food" className="itemmBannerSlide itemm-29"></a>
                        <a href="/health" className="itemmBannerSlide itemm-30"></a>
                        <a href="/sports" className="itemmBannerSlide itemm-31"></a>
                        <a href="/events" className="itemmBannerSlide itemm-32"></a>
                        <a href="/rooms" className="itemmBannerSlide itemm-33"></a>
                        <a href="/conference" className="itemmBannerSlide itemm-34"></a>

                        <a href="/food" className="itemmBannerSlide itemm-35"></a>
                        <a href="/health" className="itemmBannerSlide itemm-36"></a>
                        <a href="/sports" className="itemmBannerSlide itemm-37"></a>

                        <a href="/events" className="itemmBannerSlide itemm-38"></a>
                        <a href="/rooms" className="itemmBannerSlide itemm-39"></a>
                        <a href="/conference" className="itemmBannerSlide itemm-40"></a>
                        <a href="/food" className="itemmBannerSlide itemm-41"></a>
                        <a href="/health" className="itemmBannerSlide itemm-42"></a>
                        <a href="/sports" className="itemmBannerSlide itemm-43"></a>
                        <a href="/events" className="itemmBannerSlide itemm-44"></a>


                       
                    </div>



                </div> */}




            </div>
            {/* <div style={{ background: "#222a35" }}> */}
            <div style={{}}>
                {/* <div style={{ background: "#fff", padding: "15px 30px", border: "10px solid #222a35" }}> */}
                {/* <div className="row" style={{ boxShadow: "0px 4px 5px #888888", background: "#222a35" }}> */}
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", padding: "5px 12px", background: "#180d42" }}>
                    <div class="col-lg-9 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                        <div className="list_layout_ecucation_caption">
                            <div style={{ padding: "5px", boxSizing: "content-box" }}>
                                {/* <h1 style={{ textAlign: "left", fontSize: "25px", color: "#fff" }}>Welcome</h1> */}

                                <p style={{ textAlign: "justify", color: "#fff" }}>The Malayalee Club, Chennai, was started in 1897 under the patronage of the Royal Houses of Travancore, Kochi and Malabar. Over the years, it has become an oasis for the Keralite diaspora living in or visiting Chennai. As the society merged with the social fabric of Tamil Nadu, the club also resonated with the best of cultures from both the states, in seamless fusion. Today, world-class amenities, serviced by qualified professionals, maintained at pristine quality levels, ensure a truly fulfilling experience for all members and guests. Welcome to a slice of God’s Own Country in Chennai.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center", padding: "0px" }}>
                        <div >
                            <img style={{ width: "auto", padding: "5px" }} src={historyOld3} alt='...' />
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

            {/* <section className="aboutRow">

                <div style={{}}>
                    <div className="row" style={{ boxShadow: "0px 4px 5px #888888" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                            <div className="list_layout_ecucation_caption">
                                <div style={{ padding: "5px", boxSizing: "content-box" }}>
                                    <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Welcome</h1>

                                    <p style={{ textAlign: "justify", color: "#222a35" }}>The Malayalee Club, Chennai, was started way back in 1897 under the patronage of the Royal Houses of Travancore, Kochi and Malabar. Over the years, it has become an oasis for the Keralite diaspora living in or visiting Chennai. Merging with the social fabric of Tamil Nadu, the club has also evolved into a lively destination that resonates with a seamless fusion of the best of cultures from both the states. World-class amenities, serviced by qualified professionals, maintained at pristine quality levels, ensure a truly fulfilling experience for all members and guests. Welcome to a slice of God’s Own Country in Chennai.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center", padding: "0px" }}>
                            <div >
                                <img style={{ width: "auto" }} src={historyOld3} alt='...' />
                            </div>
                        </div>
                    </div>
                    <div className="row " style={{ boxShadow: "0px 4px 5px #888888" }}>
                        
                        <div className="col-lg-5 col-md-12 col-sm-12" style={{ paddingRight: "5px", alignSelf: "center" }}>
                            <div style={{ padding: "25px", boxSizing: "content-box" }}>
                                <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Welcome</h1>

                                <p style={{ textAlign: "justify", color: "gray" }}>The Malayalee Club, Chennai, was started way back in 1897 under the patronage of the Royal Houses of Travancore, Kochi and Malabar. Over the years, it has become an oasis for the Keralite diaspora living in or visiting Chennai. Merging with the social fabric of Tamil Nadu, the club has also evolved into a lively destination that resonates with a seamless fusion of the best of cultures from both the states. World-class amenities, serviced by qualified professionals, maintained at pristine quality levels, ensure a truly fulfilling experience for all members and guests. Welcome to a slice of God’s Own Country in Chennai.
                                    {about}
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12" >

                            <MDBCarousel showControls showIndicators style={{ alignSelf: "center" }} touch={false}>

                                <MDBCarouselItem itemId={1}>
                                    <img className="aboutSlider" src={about0} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={2}>
                                    <img className="aboutSlider" src={about1} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={3}>
                                    <img className="aboutSlider" src={about2} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={4}>
                                    <img className="aboutSlider" src={about3} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={5}>
                                    <img className="aboutSlider" src={about4} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={6}>
                                    <img className="aboutSlider" src={about5} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={7}>
                                    <img className="aboutSlider" src={about6} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={8}>
                                    <img className="aboutSlider" src={about7} alt='...' />
                                </MDBCarouselItem>

                            </MDBCarousel>

                        </div>
                    </div>
                </div>
            </section> */}
            <br />
            <section className="aboutRow" style={{ padding: "0px 40px 28px 40px" }}>

                <div >
                    <div className="row" style={{ boxShadow: "0px 4px 5px #888888" }}>
                        {/* <hr /> */}

                        <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                            <MDBCarousel showControls showIndicators style={{ alignSelf: "center" }} touch={false}>

                                <MDBCarouselItem itemId={1}>
                                    <img className="aboutSlider" src={about0} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={2}>
                                    <img className="aboutSlider" src={about1} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={3}>
                                    <img className="aboutSlider" src={about2} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={4}>
                                    <img className="aboutSlider" src={about3} alt='...' />
                                </MDBCarouselItem>
                                {/* <MDBCarouselItem itemId={5}>
                                    <img className="aboutSlider" src={about4} alt='...' />
                                </MDBCarouselItem> */}
                                <MDBCarouselItem itemId={5}>
                                    <img className="aboutSlider" src={about5} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={6}>
                                    <img className="aboutSlider" src={about6} alt='...' />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={7}>
                                    <img className="aboutSlider" src={about7} alt='...' />
                                </MDBCarouselItem>

                            </MDBCarousel>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                            {/* <div className="education_block_list_layout"> */}
                            <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Facilities and Services</h1>

                            <div style={{ textAlign: "center" }}>

                                <ul style={{ listStyleType: "circle", textAlign: "left", padding: "0px 20px 0px 20px" }}>
                                    <li style={{ listStyleType: "circle" }}>
                                        A state- of- the- art 700 seater air-conditioned auditorium
                                    </li>
                                    <li>2 Banquet Halls (upto 150 seats)</li>
                                    <li>5 Meeting Rooms (upto 50 seats)</li>
                                    <li>
                                        24 luxurious Guest Rooms

                                    </li>
                                    <li>2 Restaurants</li>


                                    <li>
                                        A Modern Elite Bar

                                    </li>

                                    <li>
                                        Indoor games facilities

                                    </li>

                                    <li>
                                        Spacious basement car-parking

                                    </li>

                                    <li>
                                        Sports facilities with Badminton / Table Tennis / Card Room / Chess / Carroms / Kids Center

                                    </li>
                                    <li>
                                        Arts and Culture Training Center

                                    </li>


                                </ul>
                                <br />
                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                </div>
            </section>

            <section className="facilitiesRow">
                {/* <div style={{ textAlign: "center" }}>
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Facilities and Services</h1>
                    <hr />
                    <ul className="columnTwo" style={{ listStyleType: "circle", padding: "0px 20px 0px 20px" }}>
                        <li style={{ listStyleType: "circle" }}>
                            A state- of- the- art 700 seater air-conditioned auditorium
                        </li>
                        <li>
                            4 Conference / Banquet Halls (50/150 seater)

                        </li>
                        <li>
                            2 Board Rooms / Meeting Rooms

                        </li>
                        <li>
                            Well-stocked Bar

                        </li>
                        <li>
                            Multi- cuisine Restaurant

                        </li>
                        <li>
                            Club-house with indoor games facilities

                        </li>
                        <li>
                            Well stocked library with Internet browsing

                        </li>
                        <li>
                            Spacious basement car-parking

                        </li>
                        <li>
                            24 luxurious four-star grade suite rooms

                        </li>
                        <li>
                            Weekly entertainment programs

                        </li>
                        <li>
                            Arts and Culture Training Center

                        </li>
                        <li>
                            Youth affairs group for skill development / Start-up Incubation / Entrepreneurship development

                        </li>
                        <li>
                            Sports facilities with Badminton / Table Tennis / Card Room / Chess / Carroms / Kids Center

                        </li>
                        <li>
                            All under one roof: A 72000 sq.ft. centrally air-conditioned building

                        </li>
                    </ul>
                    <br />
                </div> */}
                <div className="row" style={{ borderRadius: "4px" }}>

                    <div className="col-lg-4">
                        <div className="articles_grid_style" style={{ boxShadow: "7px 7px 14px #888888 " }}>
                            <div className=" articles_grid_thumb ">
                                <a href="/accommodations"><img src={RandA} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/accommodations"> <h4>Guest Rooms</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/conventions"><img src={CandB} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/conventions"> <h4>Conferences & Banquets</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", float: "right" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/food"><img src={FandB} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/food"> <h4>Bar & Restaurant</h4>
                                </Link>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/sports"><img src={SandG} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/sports"> <h4 style={{ color: "#222a35" }}>Sports & Games</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888 " }}>
                            <div className="articles_grid_thumb ">
                                <a href="/health"><img src={HandW} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/health"> <h4>Health & Wellness</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", float: "right" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/events"><img src={EandA} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/events">  <h4>Events & Activities</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>



            </section>
            <section className="eventsRow">
                <div>
                    <div className="row" style={{ display: "flex" }}>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingRight: "5px" }}>
                            <div style={{ padding: "25px", boxSizing: "borderBox", boxShadow: "0px 4px 5px #888888" }}>
                                <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Events and Activities</h1>

                                <p style={{ textAlign: "justify", color: "gray", fontSize: "13px" }}>
                                    The Club is a lively and vibrant center for all age-groups with a host of events and activities happening throughout the year. There are designated groups like ‘Wings’ for women empowerment, ‘Kidzone’ for the little-ones, ‘Smart Minds’ for the youth,
                                    ‘Sanskriti’ for Arts and Cultural initiatives and many more such groups for special interest groups among the members. Apart from that, there are regular events conducted by these groups and in general, like the musical event
                                    ‘Paatinde Paalazhi’, regular movie screenings of the latest hits which are enabled by the industry members in our club, Games and activities at the Restobar, Tambola nights etc. Each member will find a specific interest group
                                    either in the Arts or in Sports and can develop oneself to the fullest potential.
                                </p>
                                <Link to="/upcomingEvents"><button type="button" class="btn btn-primary">
                                    Upcoming Events
                                </button></Link>&nbsp;
                                <Link to="/events"><button type="button" class="btn btn-secondary">
                                    Past Events
                                </button></Link>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="container" style={{ padding: "0px" }}>

                <div className="row justify-content-center ">
                    <div className="col ">
                        <div className="sec-heading center ">
                            <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Executive Committee</h1>
                            <hr />
                        </div>
                    </div>
                </div>
                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m1} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. N. R. Panicker<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PRESIDENT</span></a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m12} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. P N Ravi<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> SECRETARY</span></a></h6>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m3} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr.S T Prabhu<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> TREASURER</span></a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m4} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Gopakumaran Nair<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> VICE PRESIDENT</span></a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m5} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Vijayan Mukundan<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> JT. SECRETARY</span></a></h6>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1"></div>

                </div>
                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                    {/* <div class="col-lg-1"></div> */}

                    {/* 2 */}
                    {/* <div class="col-lg-1"></div> */}
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m6} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. M P Farook</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m7} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. G P Vijay Kumar</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m8} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. C M Samir</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m9} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Ms. Meera Krishnankutty</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m10} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Ms. Latha Mohan</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m11} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Jose Varghese</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m2} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A N Gireeshan</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="col-lg-1"></div> */}
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m13} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. K Sivakumar</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m14} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A R Vinod Kumar Nair</a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m15} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Zachariah Cheriyan</a></h6>
                                    {/* <span style={{ visibility:"hidden" }}>1</span> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="col-lg-1"></div>*/}

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m16} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A M Gopalan <span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PAST PRESIDENT</span></a></h6>
                                    {/* <span style={{ fontSize: "10px" }}>Past President</span> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <img className="card-img-top " src={m17} alt="Card cap " />
                            <div className="card-body ">
                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. D Ganesan<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PAST SECRETARY</span></a></h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-lg-4"></div> */}

                </div>



            </section>
            {/* <Modal show={show} onHide={handleClose} className="HomeModel" >
                <Modal.Header closeButton classname="btn-close" >
                    <Modal.Title style={{ color: "white" }}>Events</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <MDBCarousel showControls showIndicators touch={false}>
                        <MDBCarouselItem itemId={1}>
                            <img style={{ height: "80vh" }} src={upevents4} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img style={{ height: "80vh" }} src={upEvents3} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img style={{ height: "80vh" }} src={upEvents1} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={4}>
                            <img style={{ height: "80vh" }} src={upEvents2} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={5}>
                            <img style={{ height: "80vh" }} src={slider1} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={6}>
                            <img style={{ height: "80vh" }} src={slider2} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={7}>
                            <img style={{ height: "80vh" }} src={slider3} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={8}>
                            <img style={{ height: "80vh" }} src={slider4} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={9}>
                            <img style={{ height: "80vh" }} src={slider5} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={10}>
                            <img style={{ height: "80vh" }} src={slider6} alt='...' />
                        </MDBCarouselItem>


                    </MDBCarousel>
                </Modal.Body>

            </Modal>
            <Modal show={show1} onHide={handleCloseActivities} className="HomeModel">
                <Modal.Header closeButton classname="btn-close">
                    <Modal.Title style={{ color: "white" }}>Activities</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <MDBCarousel showControls showIndicators touch={false}>
                        <MDBCarouselItem itemId={1}>
                            <img src={event1} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={event2} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={event3} alt='...' />
                        </MDBCarouselItem>
                    </MDBCarousel>

                </Modal.Body>

            </Modal>

            <Modal show={show12} onHide={handleCloseActivities2} className="HomeModel">
                <Modal.Header closeButton classname="btn-close">
                    <Modal.Title style={{ color: "white" }}>Activities</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <MDBCarousel showControls showIndicators touch={false}>
                        <MDBCarouselItem itemId={1}>
                            <img src={event2} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={event3} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={event1} alt='...' />
                        </MDBCarouselItem>

                    </MDBCarousel>

                </Modal.Body>

            </Modal>
            <Modal show={show13} onHide={handleCloseActivities3} className="HomeModel">
                <Modal.Header closeButton classname="btn-close">
                    <Modal.Title style={{ color: "white" }}>Activities</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <MDBCarousel showControls showIndicators touch={false}>
                        <MDBCarouselItem itemId={1}>
                            <img src={event3} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={2}>
                            <img src={event1} alt='...' />
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId={3}>
                            <img src={event2} alt='...' />
                        </MDBCarouselItem>

                    </MDBCarousel>

                </Modal.Body>

            </Modal> */}
        </div>
    );
};
