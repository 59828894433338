import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { NavLink, useNavigate } from "react-router-dom";
import './Pages/assets/css/plugins/bootstrap.min.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './Pages/assets/css/styles.css';
import "./NavBar.css";
import "./Sidebar.css";
import Logo from "./Pages/assets/images/logo3.png";
import Facilities from "./Pages/assets/images/icon/facilities.png";
import Accommodation from "./Pages/assets/images/icon/accommodation.png";
import Events from "./Pages/assets/images/icon/events.png";
import Contact from "./Pages/assets/images/icon/contact.png";
import Affiliation from "./Pages/assets/images/icon/affiliation.png";
import Membership from "./Pages/assets/images/icon/membership.png";
import PayOnline from "./Pages/assets/images/icon/payOnline.png";
import Conventions from "./Pages/assets/images/icon/conventions.png";
import About from "./Pages/assets/images/icon/about.png";

import Side_Facilities from "./Pages/assets/images/icon/sideFacilities.png";
import Side_Accommodation from "./Pages/assets/images/icon/sideAccommodation.png";
import Side_Events from "./Pages/assets/images/icon/sideEvents.png";
import Side_Contact from "./Pages/assets/images/icon/sideContact.png";
import Side_Membership from "./Pages/assets/images/icon/sideMembership.png";

import Side_Home from "./Pages/assets/images/icon/sideHome.png";
import Side_About from "./Pages/assets/images/icon/sideAbout.png";
import Side_Food from "./Pages/assets/images/icon/sideFood.png";
import Side_Affiliation from "./Pages/assets/images/icon/sideAffiliation.png";
import Side_Gallery from "./Pages/assets/images/icon/sideGallery.png";
import Side_PayOnline from "./Pages/assets/images/icon/sidePayOnline.png";
import Side_Conventions from "./Pages/assets/images/icon/sideConventions.png";

import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import { HashLink as Link } from 'react-router-hash-link';
import "@fontsource/kanit/400.css";

export const Nav2 = () => {

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 20) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);



    const [click, setClick] = useState(false);

    const navigate = useNavigate();
    const aboutNavigate = () => {
        navigate("/about");
    }
    const membershipNavigate = () => {
        navigate("/membership");
    }

    const handleClick = () => {
        // var x = document.getElementById("hideElement");

        // x.style.display = "none";
        setShowNav(!showNav);
        setClick(!click);

    }
    const handleClick2 = () => {

        setClick(!click);

    }

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);


    const showDropdownAbout = (e) => {
        setShow1(!show1);
    }
    const hideDropdownAbout = e => {
        setShow1(false);
    }
    const showDropdownFacilities = (e) => {
        setShow2(!show2);
    }
    const hideDropdownFacilities = e => {
        setShow2(false);
    }
    const showDropdownEvents = (e) => {
        setShowEvents(!showEvents);
    }
    const hideDropdownEvents = e => {
        setShowEvents(false);
    }
    const showDropdownFandB = (e) => {
        setShow3(!show3);
    }
    const hideDropdownFandB = e => {
        setShow3(false);
    }
    const showDropdownMembership = (e) => {
        setShow4(!show4);
    }
    const hideDropdownMembership = e => {
        setShow4(false);
    }
    const [showNav, setShowNav] = useState(false)

    return <div className={`body-area${showNav ? ' body-pd' : ''}`}>
        <header className={`header${showNav ? ' body-pd' : ''}`}  >


            <div className="nav-container">
                <div className={
                    colorChange
                        ? "navbar colorChange"
                        : "navbar"
                }>
                    <nav className={"navbar "} >


                        <div className="row navAlign" >


                            <div className="col-lg-3" style={{ alignSelf: "center" }}>
                                <div className="nav-header">
                                    <div className="header_toggle">
                                        <i
                                            className={`bi ${showNav ? 'bi-x' : 'bi-list'}`}
                                            onClick={() => setShowNav(!showNav)} />&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="logoSize">
                                        <a className="nav-brand static-logo" href="/"><img
                                            src={Logo} style={{ width: "100%" }} className="logo"
                                            alt="Malayalee Club Logo" /></a>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9">
                                <div className="row">


                                    <div className="col-lg-12" style={{ alignSelf: "center", textShadow: "1px 1px #ccc" }}>
                                        <div className="nav-menus-wrapper" style={{ transitionProperty: "none", float: "right" }}>

                                            <ul className={click ? "nav-menu active" : "nav-menu"} style={{ float: "right" }}>
                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/about">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/about"
                                                            className="nav-link"
                                                            activeClassName="active"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray", borderLeft: "1px solid gray" }}
                                                        >
                                                            <img src={About} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">About Us</span>
                                                        </NavLink>
                                                    </li>
                                                </a>

                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/facilities">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/facilities"
                                                            className="nav-link"
                                                            activeClassName="active"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray" }}
                                                        >
                                                            <img src={Facilities} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Facilities</span>
                                                        </NavLink>
                                                    </li>
                                                </a>
                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/accommodation">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/accommodation"
                                                            className="nav-link"
                                                            activeClassName="active"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray" }}
                                                        >
                                                            <img src={Accommodation} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Accommodation</span>
                                                        </NavLink>
                                                    </li>
                                                </a>

                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/conventions">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/conventions"
                                                            className="nav-link"
                                                            activeClassName="active"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray" }}
                                                        >
                                                            <img src={Conventions} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Conventions</span>
                                                        </NavLink>
                                                    </li>
                                                </a>


                                                <a style={{ textAlign: "left", fontWeight: "600" }}>

                                                    <li className="nav-item" >

                                                        <div style={{ borderRight: "1px solid gray", display: "grid", }}>
                                                            <img src={Membership} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} />
                                                            <span className="nav_name">
                                                                <NavDropdown className="" title="Members"
                                                                    id="collasible-nav-dropdown"
                                                                    show={show2}
                                                                    onMouseEnter={showDropdownFacilities}
                                                                    onMouseLeave={hideDropdownFacilities}
                                                                    activeClassName="active"
                                                                >
                                                                    <NavDropdown.Item href={"https://members.malayaleeclub.com/"} id="collasible-nav-dropdown"
                                                                    >
                                                                        <NavLink
                                                                            exact
                                                                            to={"https://members.malayaleeclub.com/"}
                                                                            onClick={handleClick2}
                                                                            target="_blank"
                                                                            activeClassName="active"
                                                                        >
                                                                            Members' Login
                                                                        </NavLink>
                                                                    </NavDropdown.Item>
                                                                    <NavDropdown.Item href="/membership">
                                                                        <NavLink
                                                                            exact
                                                                            to="/membership"
                                                                            onClick={handleClick2}
                                                                            activeClassName="active"
                                                                        >
                                                                            Membership Options
                                                                        </NavLink>
                                                                    </NavDropdown.Item>


                                                                </NavDropdown>
                                                            </span>
                                                        </div>

                                                    </li>
                                                </a>

                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/affiliation">

                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/affiliation"
                                                            activeClassName="active"
                                                            className="nav-link"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray" }}
                                                        >
                                                            <img src={Affiliation} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Affiliations</span>
                                                        </NavLink>
                                                    </li>
                                                </a>
                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/events">

                                                    <li className="nav-item" >

                                                        <div style={{ borderRight: "1px solid gray", display: "grid", }}>
                                                            <img src={Events} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} />
                                                            <span className="nav_name">
                                                                <NavDropdown className="" title="Events"
                                                                    id="collasible-nav-dropdown"
                                                                    show={showEvents}
                                                                    onMouseEnter={showDropdownEvents}
                                                                    onMouseLeave={hideDropdownEvents}
                                                                    activeClassName="active"
                                                                >
                                                                    <NavDropdown.Item href="/upcomingEvents" id="collasible-nav-dropdown"
                                                                    >
                                                                        <NavLink
                                                                            exact
                                                                            to="/upcomingEvents"
                                                                            onClick={handleClick2}
                                                                            activeClassName="active"
                                                                        >
                                                                            Upcoming Events
                                                                        </NavLink>
                                                                    </NavDropdown.Item>
                                                                    <NavDropdown.Item href="/events">
                                                                        <NavLink
                                                                            exact
                                                                            to="/events"
                                                                            onClick={handleClick2}
                                                                            activeClassName="active"
                                                                        >
                                                                            Past Events
                                                                        </NavLink>
                                                                    </NavDropdown.Item>


                                                                </NavDropdown>
                                                            </span>
                                                        </div>

                                                    </li>
                                                </a>

                                                {/* <a style={{ textAlign: "left", fontWeight: "600" }} href="/events">


                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/events"
                                                            activeClassName="active"
                                                            className="nav-link"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray" }}
                                                        >
                                                            <img src={Events} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Events</span>
                                                        </NavLink>
                                                    </li>
                                                </a> */}

                                                <a style={{ textAlign: "left", fontWeight: "600" }} href="/contact">

                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to="/contact"
                                                            activeClassName="active"
                                                            className="nav-link"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid" }}
                                                        >
                                                            <img src={Contact} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name">Contact Us</span>
                                                        </NavLink>
                                                    </li>
                                                </a>
                                                <a style={{ textAlign: "left", fontWeight: "600", background: "#222a35" }} target="_blank" href={"https://members.malayaleeclub.com/"}>
                                                    <li className="nav-item">
                                                        <NavLink
                                                            exact
                                                            to={"https://members.malayaleeclub.com/"}
                                                            className="nav-link"
                                                            activeClassName="active"
                                                            target="_blank"
                                                            onClick={handleClick2}
                                                            style={{ display: "grid", borderRight: "1px solid gray", borderLeft: "1px solid gray" }}
                                                        >
                                                            {/* <a className="nav-link" href={"C:/Users/SB/Documents/login/index.html"} target='_blank'>   */}
                                                            <img src={PayOnline} style={{ textAlign: "center", width: "34px", marginLeft: "auto", marginRight: "auto" }} /><span className="nav_name" style={{ color: "#fff" }}>Pay Online</span>
                                                            {/* </a> */}
                                                        </NavLink>
                                                    </li>
                                                </a>
                                                {/* <div
                                                    style={{ float: "right", backgroundColor: "#222a35", borderRadius: "19.8px", alignSelf: "center", padding: "10px 13px 10px 13px", margin: "0px 5px 0px 0px" }}>
                                                    <a href={"https://malayaleeclub.com/"} target="blank" style={{ color: "#fff" }} data-toggle="modal" data-target="#signup">PAY
                                                        ONLINE</a>
                                                </div>
                                                <div
                                                    style={{ float: "right", backgroundColor: "#222a35", borderRadius: "19.8px", alignSelf: "center", padding: "10px 13px 10px 13px", }}>
                                                    <a href={"https://admin.malayaleeclub.org/login.php"} target="blank" style={{ color: "#fff" }} data-toggle="modal"
                                                        data-target="#signup">MEMBERS LOGIN</a>
                                                </div> */}


                                            </ul>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>




                        {/* <div className="nav-icon" onClick={handleClick}>

                            {click ? (
                                <span className="icon">
                                    <HamburgetMenuOpen />{" "}
                                </span>
                            ) : (
                                <span className="icon">
                                    <HamburgetMenuClose />
                                </span>
                            )}
                        </div> */}
                    </nav >
                </div >
            </div >
        </header >
        <div className={`l-navbar${showNav ? ' show' : ''}`} onclick="parentNode.remove()">
            <nav className="nav "   >
                <div >

                    <NavLink
                        exact
                        to="/"
                        activeClassName="active"
                        onClick={handleClick}
                        className="nav_link" rel="noopener"
                        data-toggle="tooltip" title="Home"
                    >
                        <img src={Side_Home} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Home</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/about"
                        className="nav_link"
                        activeClassName="active"
                        onClick={handleClick}
                        data-toggle="tooltip" title="About Us"
                    >
                        <img src={Side_About} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">About Us</span>
                    </NavLink>

                    {/* <NavDropdown className="nav-item nav_link normal-text" title="Facilities"
                        id="collasible-nav-dropdown"
                        show={show2}
                        onMouseEnter={showDropdownFacilities}
                        onMouseLeave={hideDropdownFacilities}
                    >
                        <NavDropdown.Item href="/rooms" id="collasible-nav-dropdown"
                        >
                            <NavLink
                                exact
                                to="/rooms"
                                onClick={handleClick}
                            >
                                Rooms & Amenities
                            </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/conference">
                            <NavLink
                                exact
                                to="/conference"
                                onClick={handleClick}
                            >
                                Conference & Banquets
                            </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/food">
                            <NavLink
                                exact
                                to="/food"
                                onClick={handleClick}
                            >
                                Food and Beverages
                            </NavLink></NavDropdown.Item>
                        <NavDropdown.Item href="/health">
                            <NavLink
                                exact
                                to="/health"

                                onClick={handleClick}
                            >
                                Health & Wellness
                            </NavLink></NavDropdown.Item>
                        <NavDropdown.Item href="/sports">
                            <NavLink
                                exact
                                to="/sports"

                                onClick={handleClick}
                            >
                                Sports & Games
                            </NavLink></NavDropdown.Item>
                        <NavDropdown.Item href="/events">
                            <NavLink
                                exact
                                to="/events"

                                onClick={handleClick}
                            >
                                Events & Activities
                            </NavLink></NavDropdown.Item>
                    </NavDropdown> */}

                    <NavLink
                        exact
                        to="/facilities"
                        className="nav_link"
                        activeClassName="active"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Facilities"
                    >
                        <img src={Side_Facilities} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} />
                        {/* <i className='bi bi-grid-1x2 nav_icon' /> */}
                        <span className="nav_name" style={{fontSize:"14px"}}>Facilities</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/accommodation"
                        className="nav_link"
                        activeClassName="active"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Accommodation"
                    >
                        <img src={Side_Accommodation} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Accommodation</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/conventions"
                        className="nav_link"
                        activeClassName="active"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Conventions"
                    >
                        <img src={Side_Conventions} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Conventions</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/food"
                        className="nav_link"
                        activeClassName="active"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Food & Beverages"
                    >
                        <img src={Side_Food} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Food & Beverages</span>
                    </NavLink>



                    <NavLink
                        exact
                        to="/events"
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Events"
                    >
                        <img src={Side_Events} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Events</span>
                    </NavLink>

                    <NavLink
                        exact
                        to="/membership"
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Memberships"
                    >
                        <img src={Side_Membership} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Memberships</span>
                    </NavLink>


                    <NavLink
                        exact
                        to="/affiliation"
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Affiliations"
                    >
                        <img src={Side_Affiliation} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Affiliations</span>
                    </NavLink>

                    <NavLink
                        exact
                        to="/gallery"
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Gallery"
                    >
                        <img src={Side_Gallery} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Gallery</span>
                    </NavLink>

                    <NavLink
                        exact
                        to="/contact"
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        data-toggle="tooltip" title="Contact Us"
                    >
                        <img src={Side_Contact} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Contact Us</span>
                    </NavLink>

                    <NavLink
                        exact
                        to={"https://members.malayaleeclub.com/"}
                        activeClassName="active"
                        className="nav_link"
                        onClick={handleClick}
                        target='_blank'
                        data-toggle="tooltip" title="Pay Online"
                    >
                        <img src={Side_PayOnline} style={{ textAlign: "center", width: "20px", marginLeft: "auto", marginRight: "auto" }} /><span style={{fontSize:"14px"}} className="nav_name">Pay Online</span>
                    </NavLink>

                    <div className="nav_list">

                    </div>


                </div>

            </nav>
        </div>

    </div >
}
